import {Component, OnInit} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {ApiService} from '../../../services/api.service';
import {CommonService} from '../../../services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {any} from 'codelyzer/util/function';
import {FormBuilder} from '@angular/forms';
import Swal from 'sweetalert2';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  searchVisible: boolean;
  quickViewVisible: boolean;
  isFolded: boolean;
  isExpand: boolean;
  id = any;
  loading: boolean;
  data: any;

  constructor(private themeService: ThemeConstantService,
              public commonService: CommonService,
              private router: Router,
              private apiService: ApiService,
              private pLocation: PlatformLocation,
              private formBuilder: FormBuilder,
              private actRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.commonService.logOut();
    //   this.router.navigate(['/authentication']);
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'success',
    //     title: 'Your Session Expired due to longer Inactivity, Login Again To Continue',
    //     showConfirmButton: true,
    //   });
    // }, 3600000);

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);


    this.loading = true;


    this.actRoute.paramMap.subscribe(params => {
      this.apiService.getAdminProfile().subscribe((res) => {
          this.data = res.data;
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
    });
  }

  // tslint:disable-next-line:typedef
  // logout(): void {
  //   this.commonService.showSuccess('success', 'Logout Successfully ');
  //   this.router.navigate(['/authentication']);
  //   this.commonService.logOut();
  //   // this.ngOnDestroy();
  //   return;
  // }


  logout(): void {
    Swal.fire({
      title: 'Are you sure to logged out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Logout!',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.commonService.logOut();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Logged Out Successfully',
          showConfirmButton: false,
          timer: 1500
        });
        this.router.navigate(['/authentication']);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Logout is not deleted',
        //   'error',
        // );
      }
    });
  }


  // tslint:disable-next-line:typedef
  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  // tslint:disable-next-line:typedef
  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  // notificationList = [
  //   {
  //     title: 'You received a new message',
  //     time: '8 min',
  //     icon: 'mail',
  //     color: 'ant-avatar-' + 'blue'
  //   },
  //   {
  //     title: 'New user registered',
  //     time: '7 hours',
  //     icon: 'user-add',
  //     color: 'ant-avatar-' + 'cyan'
  //   },
  //   {
  //     title: 'System Alert',
  //     time: '8 hours',
  //     icon: 'warning',
  //     color: 'ant-avatar-' + 'red'
  //   },
  //   {
  //     title: 'You have a new update',
  //     time: '2 days',
  //     icon: 'sync',
  //     color: 'ant-avatar-' + 'gold'
  //   }
  // ];
  // tslint:disable-next-line:typedef


}
