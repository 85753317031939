import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules, ExtraOptions} from '@angular/router';

import {FullLayoutComponent} from './layouts/full-layout/full-layout.component';
import {CommonLayoutComponent} from './layouts/common-layout/common-layout.component';

import {FullLayout_ROUTES} from './shared/routes/full-layout.routes';
import {CommonLayout_ROUTES} from './shared/routes/common-layout.routes';
import {LoginGuard} from './authentication/login.guard';
import {AuthGuard} from './services/auth.guard';
import {HrGuardGuard} from './services/hr-guard.guard';
import {AppComponent} from './app.component';

const appRoutes: Routes = [
  {
    path: '',
    component: CommonLayoutComponent,
    children: CommonLayout_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication',
    component: FullLayoutComponent,
    children: FullLayout_ROUTES,
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    component: AppComponent
  }
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

