import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {CommonService} from './common.service';
import Swal from 'sweetalert2';
import {PlatformLocation} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router
    , private commonService: CommonService,
              private pLocation: PlatformLocation,) {
  }

   canActivate(): boolean {
    if (!this.commonService.loggedIn()) {
      this.router.navigateByUrl('/authentication').then();
      return false;
    } else {
      return true;
    }
  }
}
