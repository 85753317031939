import {SideNavInterface} from '../../interfaces/side-nav.type';


export const ROUTES: SideNavInterface[] = [
  {
    path: '',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: []
  },
  // 2 employee
  {
    path: '',
    title: 'Employee',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'team',
    submenu: [
      {
        path: 'department',
        title: 'Departments',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'cluster',
        submenu: []
      },
      {
        path: 'designation',
        title: 'Designations',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'solution',
        submenu: []
      },

      {
        path: 'employee',
        title: 'Employees',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: []
      },
    ]
  },

  // 3 payrol
  {
    path: '',
    title: 'Payroll',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'snippets',
    submenu: [
      {
        path: 'holiday',
        title: 'Holidays',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'calendar',
        submenu: []
      },
      {
        path: 'leave',
        title: 'Leave Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'schedule',
        submenu: []
      },
      {
        path: 'attendance',
        title: 'Upload Attendance',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'upload',
        submenu: []
      },


      {
        path: '',
        title: 'Reports',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'calendar',
        submenu: [
          {
            path: 'marketing',
            title: 'Marketing',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'calculator',
            submenu: []
          },
          {
            path: 'otherStaffs',
            title: 'Other Staffs',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'calculator',
            submenu: []
          },
        ]
      },
      {
        path: 'salCal',
        title: 'Salary Calculation',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'wallet',
        submenu: []
      },
      {
        path: 'loan',
        title: 'Loans',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'credit-card',
        submenu: []
      },
    ],
  },
  // 4 sales
  {
    path: '',
    title: 'Sales',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'line-chart',
    submenu: [
      {
        path: 'teamBuilding',
        title: 'Team Building',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: []
      },
      {
        path: 'sales',
        title: 'Sales Target',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'partition',
        submenu: []
      },
      {
        path: 'viewTarget',
        title: 'View Target',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'fund-view',
        submenu: []
      },
      {
        path: 'eod',
        title: 'Sales EOD',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-done',
        submenu: []
      },

      {
        path: 'lead',
        title: 'Visits & Reports',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-done',
        submenu: []
      },
    ]
  },


  {
    path: 'notice',
    title: 'Notice',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'notification',
    submenu: []
  },
  {
    path: 'saleTracking',
    title: 'Sales Tracking',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'environment',
    submenu: []
  },


  {
    path: 'dynamic',
    title: 'Dynamics',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'retweet',
    submenu: []
  },
  // {
  //   path: 'testlocation',
  //   title: 'Location',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'retweet',
  //   submenu: []
  // },

];
