import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  get nativeWindow(): any {
    return _window();
  }

  // tslint:disable-next-line:typedef
  loginUser(data: any) {
    return this.httpClient
      .post(`${environment.baseUrl}loginOTP`, data, {
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          return res.body;
        })
      );
  }

  resendOTP(data: any) {
    return this.httpClient
      .post(`${environment.baseUrl}resendOTP`, data, {
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          return res.body;
        })
      );
  }

  otpVerify(data: any, mobile: any, role: any) {
    return this.httpClient
      .post(
        `${environment.baseUrl}verifyOTP`,
        { otp: data, mobile, role },
        {
          observe: 'response',
        }
      )
      .pipe(
        tap((res) => {
          return res.body;
        })
      );
  }

  forgotPassword(mobile: any) {
    return this.httpClient
      .post(
        `${environment.baseUrl}forgot`,
        { mobile },
        {
          observe: 'response',
        }
      )
      .pipe(
        tap((res) => {
          return res.body;
        })
      );
  }

  // tslint:disable-next-line:typedef
  tokenVerification(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}tokenVerification`,
      form
    );
  }

  // tslint:disable-next-line:typedef
  reset(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}reset`, form);
  }

  getAdminProfile(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}adminDetail`);
  }

  // tslint:disable-next-line:typedef
  changePassword(form) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}user/changePassword`,
      form
    );
  }

  getBlogList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}blog/getBlogList`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  deleteBlog(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}blog/${id}`,
      {}
    );
  }

  getBlogData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}blog/${id}`);
  }

  saveProduct(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}product`,
      form
    );
  }

  uploadFile(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}candidates/uploadCV`,
      form
    );
  }

  getTermData(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}term`);
  }

  updateCodeEthicsBusiness(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}codeEthicsBusiness`,
      form
    );
  }

  getClientId(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}client/${id}`);
  }

  getEmployee(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}user/get_users/${id}`
    );
  }

  saveHr(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}candidates`,
      form
    );
  }

  getAdminList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}user/getAllListAdmin`,
      { start, name, dir, pageSize, search }
    );
  }

  //   For Category

  getDepartmentList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}department/getDepartment`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  getDropdownDepartmentList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}department/getDepartmentForEmployeeDropdown`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  deleteDepartment(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}department/${id}`,
      {}
    );
  }

  getDepartmentByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}department/${id}`
    );
  }

  saveDepartment(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}department`,
      form
    );
  }

  //  For Sub Category
  getDesignationList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}designation/getDesignation`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  deleteDesignation(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}designation/${id}`,
      {}
    );
  }

  getDesignationByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}designation/${id}`
    );
  }

  saveDesignation(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}designation`,
      form
    );
  }

  //   For Employee
  saveEmp(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}employee`,
      form
    );
  }

  getEmployeeList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}employee/getEmployeeList`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  getEmployeeListAddTeam(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}employee/getEmployeeListForTeamAdd`,
      {}
    );
  }

  getEmployeeByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}employee/${id}`
    );
  }

  deleteEmployee(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}employee/${id}`,
      {}
    );
  }

  upload(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}upload`, form);
  }

  changeUserStatus(form: any): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}employee/changeStatus`,
      form
    );
  }

  // For Holiday
  getHolidayList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}holiday/getHoliday`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  deleteHoliday(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}holiday/${id}`,
      {}
    );
  }

  getHolidayByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}holiday/${id}`);
  }

  saveHoliday(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}holiday`,
      form
    );
  }

  // Dynamic

  getDynamicByIdData(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}dynamic/1`);
  }

  saveDynamic(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}dynamic`,
      form
    );
  }

  //   Leave
  getLeaveList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}leave/getEmployeeLeaveList`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  getLeaveByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}leave/${id}`);
  }

  changeLeaveStatus(form: any): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}leave/setStatus`,
      form
    );
  }

  deleteLeave(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}leave/${id}`,
      {}
    );
  }

  onHandSalray(form: any): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}employee/salaryCal`,
      form
    );
  }

  //  Leads Submission
  getLeadList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    eId?: string,
    startDate?: string,
    endDate?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}lead/getLeads`,
      { start, name, dir, pageSize, search, eId, startDate, endDate }
    );
  }

  getLeadByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}lead/${id}`);
  }

  getLeadMarketingPerosn(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}lead/getMarkettingList`,
      {}
    );
  }

  getData(): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}Productapi/CategoryList`
    );
  }

  // For Notice
  getNoticeList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}notice/getNotice`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  deleteNotice(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}notice/${id}`,
      {}
    );
  }

  getNoticeByIdData(id: string): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}notice/${id}`);
  }

  saveNotice(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}notice`, form);
  }

  uploadAttdance(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}punch`, form);
  }

  uploadMarketingAttdance(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}punch/marketingAttendance`,
      form
    );
  }

  uploadDatFile(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}uploadDat`,
      form
    );
  }

  getMarketingPersonList(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}punch/getMarketingPerson`,
      {}
    );
  }

  getOtherStaffs(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}punch/getOtherStaffs`,
      {}
    );
  }

  uploadOtherStaffsAttdance(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}punch/otherStaffAttendance`,
      form
    );
  }

  salCal(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}salary`, form);
  }

  // uploadSalaryCal(form: any) {
  //   return this.httpClient.post<Response>(`${environment.baseUrl}salary`, form);
  // }

  uploadSalaryCal(data1: any, data2: any, data3: any, data4: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}uploadSalaryCal`,
      {
        data: data1,
        month: data2,
        year: data3,
        override: data4,
      }
    );
  }

  saveLoan(form: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}loan`, form);
  }

  getLoanList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    eId?: string,
    startDate?: string,
    endDate?: string,
    csv?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}loan/getLoan`,
      { start, name, dir, pageSize, search, eId, startDate, endDate, csv }
    );
  }

  //   getLeadList(start?: any, name?: string, dir?: string,
  //             pageSize?: number, search?: string, eId?: string, startDate?: string, endDate?: string): Observable<Response> {
  //   return this.httpClient.post<Response>(`${environment.baseUrl}lead/getLeads`,
  //     {start, name, dir, pageSize, search, eId, startDate, endDate});
  // }

  closeLoan(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}loan/${id}`,
      {}
    );
  }

  paySlipMail(data1: any, data2: any) {
    return this.httpClient.post<Response>(`${environment.baseUrl}generatePDF`, {
      data: data1,
      paySlip: data2,
    });
  }

  storeExecutive(form: any, executiveList) {
    return this.httpClient.post<Response>(`${environment.baseUrl}saleTarget`, {
      data: form,
      executiveList: executiveList,
    });
  }

  getManList(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/getManager`,
      {}
    );
  }

  getExecutiveList(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/getExecutive`,
      {}
    );
  }

  getSalesList(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/targetList`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  getSaleByID(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}saleTarget/${id}`
    );
  }

  deleteSale(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(
      `${environment.baseUrl}saleTarget/${id}`,
      {}
    );
  }

  getExecutiveDropData(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/eodListSM`,
      {}
    );
  }

  // after month year updateget single executive list

  getExecutiveDropData1(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/getExecutive`,
      {}
    );
  }

  showExecutiveMonthReport(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/showTarget`,
      form
    );
  }

  updateTarget(data) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}saleTarget/updateTargets`,
      {
        data: data,
      }
    );
  }

  storeTeam(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding`,
      form
    );
  }

  getTeamData(
    start?: any,
    name?: string,
    dir?: string,
    pageSize?: number,
    search?: string,
    proId?: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getTeamBuilding`,
      { start, name, dir, pageSize, search, proId }
    );
  }

  getHeadTeam(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getHeadList`,
      {}
    );
  }

  getTeamByID(id: string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}teamBuilding/${id}`
    );
  }

  getManagerUsingCountryHeadId(countryHead_id: any): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getManagerUsingCountryHeadId`,
      { countryHead_id }
    );
  }

  getExecutiveListUsingManagerId(
    zonalSalesManager_id: any
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getExecutiveListUsingmanagerId`,
      { zonalSalesManager_id }
    );
  }

  getHeadEmpList(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getHeadEmpList`,
      {}
    );
  }

  getManEmpList(): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/getManEmpList`,
      {}
    );
  }

  viewZonalSalesManager(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}teamBuilding/viewZonalSalesManager`,
      form
    );
  }

  distanceEmployee(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}location/empDistance`,
      form
    );
  }

  addressEmployee(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}location/currentLocation`,
      form
    );
  }

  polylineData(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}location/polylineData`,
      form
    );
  }

  newLeave(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}newLeave`);
  }

  newkm(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}totalKm`);
  }

  avrageKM(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}averageKM`);
  }

  salesPerDaySqFeet(): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.baseUrl}saleThisMonth`);
  }

  collectionThisMonth(): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}collectionThisMonth`
    );
  }

  travelReport(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}location/travelReport`,
      form
    );
  }

  monthlyReport(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}location/monthlyReport`,
      form
    );
  }
  getMonthWiseSalaryGetUsingIdAndMonth(id: string,monthYear:string): Observable<Response> {
    return this.httpClient.get<Response>(
      `${environment.baseUrl}getSalaryDataMonthWise/${id}/${monthYear}`
    );
  }
  salaryPdf(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}salaryPdf`,
      form
    );
  }
  salMonthWiseUpdate(form: any) {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}updateSalaryPreview`,
      form
    );
  }


}

export interface Response {
  message: string;
  data: any;
  status: boolean;
  total: number;
  invalid: boolean;
  code: number;
}
