import {Component, OnInit} from '@angular/core';
import {ROUTES} from './side-nav-routes.config';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {Router} from '@angular/router';
import {CommonService} from '../../../services/common.service';
import {ROUTESHR} from './HRside-nav-routes.config';
import {ROUTESBD} from './BDside-nav-routes.config';
import {ROUTESSM} from './SMside-nav-routes.config';
import {RouteCoutryHead} from './CoutryHeadSide-nav-routes.config';

@Component({
  selector: 'app-sidenav',
  styleUrls: ['./side-nav.component.css'],
  templateUrl: './side-nav.component.html'
})

export class SideNavComponent implements OnInit {

  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  constructor(private themeService: ThemeConstantService,
              private commonService: CommonService,
              private router: Router) {
  }

  ngOnInit(): void {
    const localData = localStorage.getItem(btoa('role'));
    const roleType = this.commonService.getRoleType();
    let role = '';
    let roleTypeString;
    role = atob(localData);

    if (this.commonService.loggedIn()) {
      role = atob(localData);
      roleTypeString = atob(roleType);
      if (role === 'admin') {
        this.router.navigate(['/']).then();
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
      } else if (role !== 'admin') {
        if (role === 'emp') {
          // 3 == zonal sales manager 5 country head
          if (roleTypeString === '3') {
            this.menuItems = ROUTESSM.filter(menuItem => menuItem);
            this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
            this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
            this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
          } else  if (roleTypeString === '5') {
            this.menuItems = RouteCoutryHead.filter(menuItem => menuItem);
            this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
            this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
            this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
          }
        }
      }
    }
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
}
