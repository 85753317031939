import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {Router} from '@angular/router';
import {CommonService} from '../services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  passwordVisible = false;
  passwordVisible1 = false;
  passwordVisible2 = false;
  password: string;
  changePassForm: FormGroup;
  submitted: boolean;
  loading1 = false;

  submitForm(): void {
    for (const i in this.changePassForm.controls) {
      this.changePassForm.controls[i].markAsDirty();
      this.changePassForm.controls[i].updateValueAndValidity();
    }
  }

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private apiService: ApiService,
              public commonService: CommonService) {
  }

  ngOnInit(): void {
    this.changePassForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: this.passwordConfirming,
    });
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return {invalid: true};
    }
  }

  get rf() {
    return this.changePassForm.controls;
  }

  reset() {
    if (this.changePassForm.invalid) {
      this.commonService.showError('error', 'enter Required Fields');
      this.submitted = true;
      return;
    }
    this.loading1 = true;
    const form = {
      password: this.changePassForm.controls.password.value,
      confirmPassword: this.changePassForm.controls.confirmPassword.value,
      oldPassword: this.changePassForm.controls.oldPassword.value,
    };
    this.apiService.changePassword(form).subscribe((res: any) => {
      if (res.status === true) {
        this.commonService.showSuccess('success', res.message);
        this.router.navigate(['/']).then();
      } else {
        this.commonService.showError('error', res.message);
      }
      this.loading1 = false;
    }, () => {
      this.loading1 = false;
    });
  }
}
