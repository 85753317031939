import {Component, OnInit} from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SeoServicesService} from './services/seo-services.service';
import {CommonService} from './services/common.service';
import {ApiService} from './services/api.service';
import {LocationStrategy, PlatformLocation} from '@angular/common';
import Swal from 'sweetalert2';
import GLightbox from 'glightbox';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  loading = true;
  lightbox: any;

  constructor() {

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  ngOnInit(): void {
    this.lightbox = GLightbox({
      selector: '.glightbox'
    });
  }
}
