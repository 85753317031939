<div class="header">
  <div class="logo logo-dark logoBack">
    <a style="cursor: default">
      <img style="width: 60%; margin: 5px" src="assets/images/logo/main_logo.svg" alt="Logo">
      <img class="logo-fold" style="width: 86%;  margin: 0 0 0 5px;" src="assets/images/logo/favicon.png" alt="Logo">
    </a>
  </div>
  <div class="logo logo-white">
    <a>
      <img style="width: 60%; margin: 5px" src="assets/images/logo/main_logo.svg" alt="Logo">
      <img class="logo-fold" style="width: 58%;  margin: auto;" src="assets/logo/old/loader.gif" alt="Logo">
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"
             style="color:#A71522!important;"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <nz-spin [nzSpinning]="loading" *ngIf="data" nzTip="Loading Detail..." [nzSize]="'large'">
        <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                      [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" class="img" nzSrc="assets/images/avatars/admin.svg"></nz-avatar>
                </span>
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <ul nz-menu class="p-b-15 p-t-20">
              <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                <div class="d-flex m-r-50">
                  <nz-avatar nzSize="large" class="img" nzSrc="assets/images/avatars/admin.svg"></nz-avatar>
                  <div class="m-l-10">
                    <p class="m-b-0 text-dark font-weight-semibold">{{data.name}}</p>
                    <p class="m-b-0 opacity-07">{{data.email}}</p>
                  </div>
                </div>
              </li>
              <!--              <li nz-menu-item>-->
              <!--                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none"-->
              <!--                   routerLink="adminDetail">-->
              <!--                  <div>-->
              <!--                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>-->
              <!--                    <span class="m-l-10">View Profile</span>-->
              <!--                  </div>-->
              <!--                  <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>-->
              <!--                </a>-->
              <!--              </li>-->
              <li nz-menu-item routerLink="changePassword">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                    <span class="m-l-10">Change Password</span>
                  </div>
                  <!--                  <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>-->
                </a>
              </li>
              <li nz-menu-item (click)="logout()">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                    <span class="m-l-10">Logout</span>
                  </div>
                  <!--                  <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>-->
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </li>
      </nz-spin>
    </ul>
  </div>
</div>
