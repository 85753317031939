<div class="container">
  <nz-card nzTitle="Change Password">
    <form nz-form [formGroup]="changePassForm" class="needs-validation" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <nz-form-label nzRequired nzFor="oldPassword">Old Password</nz-form-label>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your old password!">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Old Password"
                       formControlName="oldPassword" id="oldPassword"
                       [(ngModel)]="password"/>
                <div *ngIf="submitted && rf.oldPassword.errors" class="text-danger invalid-feedback">
                  <div *ngIf="rf.oldPassword.errors.required">Old Password is required</div>
                  <div *ngIf="rf.oldPassword.errors.maxlength">Your password cannot exceed 20 characters.
                  </div>
                </div>
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                   (click)="passwordVisible = !passwordVisible"></i>
              </ng-template>

            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <nz-form-label nzRequired nzFor="newPassword">New Password</nz-form-label>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your New Password!">
              <nz-input-group [nzSuffix]="suffixTemplate3">
                <input nz-input [type]="passwordVisible1 ? 'text' : 'password'" placeholder="New Password"
                       minlength="4" maxlength="20" id="password"
                       [class.is-invalid]="submitted && rf.password.errors"
                       formControlName="password" required>
              </nz-input-group>
              <ng-template #suffixTemplate3>
                <i nz-icon [nzType]="passwordVisible1 ? 'eye-invisible' : 'eye'"
                   (click)="passwordVisible1 = !passwordVisible1"></i>
              </ng-template>

            </nz-form-control>
            <div *ngIf="submitted && rf.password.errors" class="text-danger invalid-feedback">
              <div *ngIf="rf.password.errors.required">Password is required</div>
              <div *ngIf="rf.password.errors.minlength">Password must be at least 4 characters long.</div>
              <div *ngIf="rf.password.errors.maxlength">Your password cannot exceed 20 characters.</div>
            </div>
          </nz-form-item>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <nz-form-label nzRequired nzFor="confirmPassword">Confirm</nz-form-label>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please confirm your New Password!">
              <nz-input-group [nzSuffix]="suffixTemplate2">
                <input nz-input type="password" minlength="4" maxlength="20"
                       placeholder="Confirm Password"
                       [type]="passwordVisible2 ? 'text' : 'password'"
                       id="confirmPassword" formControlName="confirmPassword" required
                       [class.is-invalid]="((submitted && rf.confirmPassword.errors) || submitted && changePassForm.errors)">
                <div *ngIf="submitted && changePassForm.errors && !rf.confirmPassword.errors"
                     class="invalid-feedback">
                  <div *ngIf="changePassForm.errors.invalid">Password does not match</div>
                </div>
              </nz-input-group>
              <div *ngIf="submitted && rf.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="rf.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="rf.confirmPassword.errors.invalid">Password does not match</div>
                <div *ngIf="rf.confirmPassword.errors.minlength">Confirm Password must be at least 4
                  characters long.
                </div>
                <div *ngIf="rf.confirmPassword.errors.maxlength">Your password cannot exceed 20
                  characters.
                </div>
              </div>
            </nz-form-control>

          </nz-form-item>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
        </div>
        <ng-template #suffixTemplate2>
          <i nz-icon [nzType]="passwordVisible2 ? 'eye-invisible' : 'eye'"
             (click)="passwordVisible2 = !passwordVisible2"></i>
        </ng-template>
        <div class="col-sm-12 col-md-12" style="text-align: end">
          <nz-form-item>
            <nz-form-control>
              <button
                      class="addBtn btn btn-primary ant-btn-success border-0 text-white mr-2"
                      nz-button nzType="primary" (click)="reset()">
                <i class="fas fa-lock mr-2"></i>Change
              </button>
              <button class="btn  btn-outline-danger m-r-10" nz-button nzType="link" routerLink="/">
                Cancel
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </nz-card>
</div>
