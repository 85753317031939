import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {CommonService} from '../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private route: Router, private commonService: CommonService) {
  }

  canActivate(): boolean {
    if (this.commonService.loggedIn()) {
      const localData = this.commonService.getRole();
      const roleType = this.commonService.getRoleType();
      let role = '';
      let roleTypeString;
      if (this.commonService.loggedIn()) {
        role = atob(localData);
        roleTypeString = atob(roleType);
        if (role === 'admin') {
          this.route.navigate(['/']).then();
        } else if (role !== 'admin') {
          if (role === 'emp') {
            if (roleTypeString === '3') {
              this.route.navigate(['/salesManager']).then();
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }
}
