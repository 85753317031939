<!--<perfect-scrollbar class="side-nav" sideNav>-->
<!--  <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" style="display: contents!important;"-->
<!--      [ngClass]="{-->
<!--            'ant-menu-inline-collapsed': isFolded,-->
<!--            'ant-menu-dark' : isSideNavDark-->
<!--        }"-->
<!--  >-->

<!--    &lt;!&ndash;    ant-menu ant-menu-inline ant-menu-sub dropdown-menu ng-star-inserted&ndash;&gt;-->
<!--    <li [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"-->
<!--        *ngFor="let item of menuItems"-->
<!--        [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"-->
<!--        [routerLinkActiveOptions]="{exact: true}">-->
<!--      <a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="item.submenu.length > 0">-->
<!--        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>-->
<!--        <i *ngIf="item.iconType == 'fontawesome'" [ngClass]="[item.iconTheme , item.icon]"></i>-->
<!--        <span class="m-l-20">{{item.title}}</span>-->
<!--        <i class="ant-menu-submenu-arrow"></i>-->
<!--      </a>-->
<!--      <a [routerLink]="item.path" (click)="closeMobileMenu()" *ngIf="item.submenu.length === 0">-->
<!--        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>-->
<!--        <i *ngIf="item.iconType == 'fontawesome'" [ngClass]="[item.iconTheme , item.icon]"></i>-->
<!--        <span class="m-l-20">{{item.title}}</span>-->
<!--      </a>-->

<!--      &lt;!&ndash; Second Level &ndash;&gt;-->
<!--      <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">-->
<!--        <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"-->
<!--            *ngFor="let subItem of item.submenu"-->
<!--            [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"-->
<!--            [routerLinkActiveOptions]="{exact: true}">-->
<!--          <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">-->
<!--            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>-->
<!--            <i *ngIf="subItem.iconType == 'fontawesome'"-->
<!--               [ngClass]="[subItem.iconTheme , subItem.icon]"></i>-->
<!--            <span >{{subItem.title}}</span>-->
<!--            <i class="ant-menu-submenu-arrow"></i>-->
<!--          </a>-->
<!--          <a [routerLink]="subItem.path" (click)="closeMobileMenu()" class="p-l-30"-->
<!--             *ngIf="subItem.submenu.length === 0">-->
<!--            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>-->
<!--            <i *ngIf="subItem.iconType == 'fontawesome'"-->
<!--               [ngClass]="[subItem.iconTheme , subItem.icon]"></i>-->
<!--            <span>{{subItem.title}}</span>-->
<!--          </a>-->

<!--          &lt;!&ndash; Third Level &ndash;&gt;-->
<!--          <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu"-->
<!--              *ngIf="subItem.submenu.length > 0">-->
<!--            <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"-->
<!--                *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"-->
<!--                [routerLinkActiveOptions]="{exact: true}">-->
<!--              <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">-->
<!--                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>-->
<!--                <i *ngIf="subItem.iconType == 'fontawesome'"-->
<!--                   [ngClass]="[subItem.iconTheme , subItem.icon]"></i>-->
<!--                <span>{{subItem.title}}</span>-->
<!--                <i class="ant-menu-submenu-arrow"></i>-->
<!--              </a>-->
<!--              <a [routerLink]="subItem.path" (click)="closeMobileMenu()" class="p-l-50"-->
<!--                 *ngIf="subItem.submenu.length === 0">-->
<!--                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>-->
<!--                <i *ngIf="subItem.iconType == 'fontawesome'"-->
<!--                   [ngClass]="[subItem.iconTheme , subItem.icon]"></i>-->
<!--                <span>{{subItem.title}}</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </li>-->
<!--  </ul>-->
<!--</perfect-scrollbar>-->


<perfect-scrollbar class="side-nav" sideNav>

  <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu"
      [ngClass]="{
            'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark
        }"
  >
    <li [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
        *ngFor="let item of menuItems"
        [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
        [routerLinkActiveOptions]="{exact: true}">
      <a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="item.submenu.length > 0">
        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
        <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
        <span>{{item.title}}</span>
        <i class="ant-menu-submenu-arrow"></i>
      </a>
      <a [routerLink]="item.path" (click)="closeMobileMenu()" *ngIf="item.submenu.length === 0">
        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
        <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
        <span>{{item.title}}</span>
      </a>

      <!-- Second Level -->
      <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
        <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
            *ngFor="let subItem of item.submenu"
            [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
            [routerLinkActiveOptions]="{exact: true}">
          <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
               [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
            <span>{{subItem.title}}</span>
            <i class="ant-menu-submenu-arrow"></i>
          </a>
          <a [routerLink]="subItem.path" (click)="closeMobileMenu()" class="p-l-30"
             *ngIf="subItem.submenu.length === 0">
            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
               [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
            <span>{{subItem.title}}</span>
          </a>

          <!-- Third Level -->
          <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
            <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"
                [routerLinkActiveOptions]="{exact: true}">
              <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                   [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                <span>{{subItem.title}}</span>
                <i class="ant-menu-submenu-arrow"></i>
              </a>
              <a [routerLink]="subItem.path" (click)="closeMobileMenu()" class="p-l-50"
                 *ngIf="subItem.submenu.length === 0">
                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                   [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                <span>{{subItem.title}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</perfect-scrollbar>
