import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NZ_I18N, en_US} from 'ng-zorro-antd/i18n';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';

import {registerLocaleData, PathLocationStrategy, LocationStrategy, CurrencyPipe, DatePipe} from '@angular/common';
import en from '@angular/common/locales/en';


import {AppRoutingModule} from './app-routing.module';
import {TemplateModule} from './shared/template/template.module';
import {SharedModule} from './shared/shared.module';

import {AppComponent} from './app.component';
import {CommonLayoutComponent} from './layouts/common-layout/common-layout.component';
import {FullLayoutComponent} from './layouts/full-layout/full-layout.component';

import {ThemeConstantService} from './shared/services/theme-constant.service';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthHttpRequestInterceptor} from './services/auth-http-request.interceptor';
import {AuthGuard} from './services/auth.guard';
import {LoginGuard} from './authentication/login.guard';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MomentModule} from 'angular2-moment';
import {TableService} from './shared/services/table.service';
import {PercentageDirectiveDirective} from './services/percentage-directive.directive';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {AgmCoreModule} from "@agm/core";
import { DownloadComponent } from './sal-cal/download/download.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    ChangePasswordComponent,
    PercentageDirectiveDirective,
    DownloadComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NzAlertModule,
    NzTabsModule,
    NzCardModule,
    NzAvatarModule,
    NzUploadModule,
    NzButtonModule,
    NzGridModule,
    NzInputModule,
    NzDatePickerModule,
    NzSelectModule,
    NzFormModule,
    ReactiveFormsModule,
    NzSpinModule,
    NgbModule,
    NgxImageZoomModule,
    NgIdleKeepaliveModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MomentModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAWD8eB6Tv8qHXmCKDdD8FHMXGKOnLX3Lo',
      libraries: ['places', 'drawing', 'geometry',],
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpRequestInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    AuthGuard,
    LoginGuard,
    ThemeConstantService,
    NzNotificationService,
    TableService,
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
