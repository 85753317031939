import {Routes} from '@angular/router';
import {ChangePasswordComponent} from '../../change-password/change-password.component';

// tslint:disable-next-line:variable-name
export const CommonLayout_ROUTES: Routes = [
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
    data: {
      title: 'Change Password',
      headerDisplay: 'none'
    },
  },

  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule),
      },
    ]
  }
];
