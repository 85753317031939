import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommonService} from './common.service';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpRequestInterceptor implements HttpInterceptor {
  constructor(private commonService: CommonService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const localData = this.commonService.getToken();
    let token = '';
    if (this.commonService.loggedIn()) {
      token = atob(localData);
    }
    const req = request.clone({
      setHeaders: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + token,
      },
    });
    // @ts-ignore
    return next.handle(req)
      .pipe(tap(event => {
          if (event instanceof HttpResponse) {
            // if (event.body.status === false) {
            //   this.commonService.showError('error',event.body.message);
            // }
            return event;
          }
        }, err => {
          let message = '';
          if (err.status === 450) {
            message = 'Link Expired';
          } else if (err.status === 401) {
            message = 'You are not authorised to view this page';
          } else if (err.status === 500) {
            message = 'Something went wrong';
          } else if (err.status === 422) {
            Object.keys(err.error.errors).forEach(key => {
              this.commonService.showError('error',err.error.errors[key], );
            });
            return err.status;
          } else {
            message = 'Error connecting to server';
          }
          this.commonService.showError('error', message);
            if (err.status === 401 || err.status === 450) {
            this.commonService.logOut();
            this.router.navigate(['authentication']);
          }
        }
      ));
  }
}
