import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  toggleMenu = new BehaviorSubject(true);
  fileUrl = environment.fileUrl;

  constructor(private notification: NzNotificationService,
              private http: HttpClient) {
  }


  download(url: string): Observable<Blob> {
    return this.http.get(this.fileUrl, {
      responseType: 'blob'
    });
  }


  showSuccess(type: string, message: string): void {
    this.notification.create(
      type,
      'Success',
      message
    );
  }

  showError(type: string, message: string): void {
    this.notification.create(
      type,
      'Error',
      message
    );
  }

  error(type: string): void {
    this.notification.create(
      type,
      'Error',
      'Something Went Wrong'
    );
  }


  // tslint:disable-next-line:typedef
  public checkInt(event) {
    return !(event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57));
  }


  public onlyNumber(event) {
    return !(event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57)) || (event.keyCode === 32);
  }

  // tslint:disable-next-line:typedef
  btoa(str: string) {
    return btoa(str);
  }

  // tslint:disable-next-line:typedef
  public checkInt3(event) {
    return !(event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57));
  }

  // tslint:disable-next-line:typedef
  public checkAlphaNum(event) {
    return (event.keyCode > 47 && event.keyCode <= 57) ||
      (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122);
  }

  // tslint:disable-next-line:typedef
  loggedIn() {
    if (!!localStorage.getItem(btoa('token'))) {
      return true;
    } else {
      return false;
    }
  }

  // tslint:disable-next-line:typedef
  logOut() {
    localStorage.clear();
  }

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return {cannotContainSpace: true}
    }

    return null;
  }

  // getDataFromLocal() {
  //   const data = localStorage.getItem(btoa('data'));
  //   if (data) {
  //     return JSON.parse(atob(data));
  //   } else {
  //     return false;
  //   }
  // }

  // tslint:disable-next-line:typedef
  getToken() {
    return localStorage.getItem(btoa('token'));
  }

  getRole() {
    return localStorage.getItem(btoa('role'));
  }

  getRoleType() {
    return localStorage.getItem(btoa('roleType'));
  }

  roleType() {
    return atob(this.getRoleType());
  }

  role() {
    return atob(this.getRole());
  }


  // tslint:disable-next-line:typedef
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // tslint:disable-next-line:typedef variable-name
  btoaString(string: any) {
    return btoa(string);
  }

  // tslint:disable-next-line:typedef
  getGender(name: string) {
    switch (name) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'T':
        return 'Transgender';
      default:
        return name;
    }
  }

  // tslint:disable-next-line:typedef
  checkString(name: string) {
    if (name === '' || name === null) {
      return '-';
    } else {
      return name;
    }
  }

  public checkAlphaNumeric(event) {
    return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode === 32 || (event.keyCode >= 97 && event.keyCode <= 122));
  }

}
