import {SideNavInterface} from '../../interfaces/side-nav.type';


export const RouteCoutryHead: SideNavInterface[] = [
  {
    path: 'viewTarget',
    title: 'View Target',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'fund-view',
    submenu: []
  },
  {
    path: 'eod',
    title: 'Sales EOD',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'file-done',
    submenu: []
  },
  {
    path: 'lead',
    title: 'Visits & Reports',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'file-done',
    submenu: []
  },
];
