export const environment = {
  production: false,
  // baseUrl: 'https://crm.prasoacp.in/api/public/api/',
  // cvUrl: 'https://crm.prasoacp.in/api/public/cv/',
  // fileUrl: 'https://crm.prasoacp.in/api/public/document/',
  // profileUrl: 'https://crm.prasoacp.in/api/public/profile/',
  // slipURL: 'https://crm.prasoacp.in/api/public/files/',
  // baseUrl: 'http://127.0.0.1:8000/api/',
  // cvUrl: 'http://127.0.0.1:8000/public/cv/',
  // fileUrl: 'http://127.0.0.1:8000/document/',
  // profileUrl: 'http://127.0.0.1:8000/profile/',
  // slipURL: 'http://127.0.0.1:8000/files/',
  baseUrl: 'https://dev.prasoacp.in/api/public/api/',
  cvUrl: 'https://dev.prasoacp.in/api/public/cv/',
  fileUrl: 'https://dev.prasoacp.in/api/public/document/',
  profileUrl: 'https://dev.prasoacp.in/api/public/profile/',
  slipURL: 'https://dev.prasoacp.in/api/public/files/',
};
